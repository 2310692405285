
import { createVNode, defineComponent, ref, onMounted } from 'vue'
import { Modal, notification } from 'ant-design-vue'
import { ExclamationCircleOutlined, HomeOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import category from '../../../services/category'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    HomeOutlined,
    PlusOutlined,
    EditOutlined,
    DeleteOutlined
  },
  setup () {
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const courseCategories = ref<Array<{ _id: string; title: string; description: string; image: string; parent_id: string }>>([])
    const showDeleteConfirm = (courseCategory, subcategory, type) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode('div', { style: 'color:red;' }, 'Do you really want to delete this record?. This process cannot be undo.'),
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk () {
          try {
            if (type === 'parent') {
              category.deleteCategory(courseCategory._id)
              const index = courseCategories.value.findIndex((n) => n._id === courseCategory._id)
              if (index >= 0) {
                courseCategories.value.splice(index, 1)
              }
            } else if (type === 'sub') {
              category.deleteCategory(subcategory._id)
              const index = courseCategory.child.findIndex((n) => n._id === subcategory._id)
              if (index >= 0) {
                courseCategory.child.splice(index, 1)
              }
            }
          } catch (error) {
            console.log(error)
            notify('Error', error.data, 'error')
          }
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    const getCategories = async () => {
      try {
        const res = await category.getCategory()
        courseCategories.value = res.data
      } catch (error) {
        console.log(error)
        notify('Error', error.data, 'error')
      }
    }
    const router = useRouter()
    const showEditForm = (courseCategory) => {
      router.push({
        path: '/category/edit/' + courseCategory._id
      })
    }
    const showSubEditForm = (courseCategory) => {
      router.push({
        path: '/category/edit/' + courseCategory._id + '/sub'
      })
    }
    const addSubCat = (courseCategory) => {
      router.push({
        path: '/category/add/' + courseCategory._id + '/sub-category'
      })
    }
    onMounted(() => {
      getCategories()
    })
    return {
      showDeleteConfirm,
      notify,
      getCategories,
      courseCategories,
      showEditForm,
      addSubCat,
      showSubEditForm
    }
  }
})
